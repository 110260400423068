import React from 'react'
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import './Index.css';
import img1 from '../images/img1.jpg';
import course1 from '../images/course1.png';
import course2 from '../images/course2.png';
import course3 from '../images/course3.png';
import course4 from '../images/course4.png';
import course5 from '../images/course5.png';
import bundle1 from '../images/bundle1.jpeg';
import bundle2 from '../images/bundle2.png';
import bundle3 from '../images/bundle3.png';
import bundle4 from '../images/bundle4.png';
import bundle5 from '../images/bundle5.png';
import courses from '../images/Courses.jpeg';
import designgurus from '../images/designgurus.png';
import permitio from '../images/permitio.png';
import coupons from '../images/Coupons.jpg';
import first from '../images/main-banner.png';
import second from '../images/second-banner.png';
import third from '../images/third-banner.png';
import fourth from '../images/fourth-banner.png';
import python from '../images/python-icon.png';
import react from '../images/react-icon.png';
import javascript from '../images/javascript-icon.png';
import java from '../images/java-icon.png';
import csharp from '../images/csharp-icon.png';
import angular from '../images/angular-icon.png';
import LoadingBar from 'react-top-loading-bar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Index() {
    window.scrollTo(0, 0)
    const d = new Date();
    let year = d.getFullYear().toString();

    window.history.pushState({}, null, '/');

    return (
        <>
            <LoadingBar color='orangered' progress={100} height={5} />
            <Helmet>
                <title>Coding Courses Hub | Online Courses for Creatives,  Learning Paths, Interactive Courses {year}</title>
                <meta name="description" content="Coding Courses Hub is an internet based stage that has all that computer programmers need to get ready for their coding and framework configuration interviews." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                    <div className="col-md-5 mb-md-0 mb-4">
                        <LazyLoadImage effect="blur" src={first} style={{ width: '100%' }} />
                    </div>
                    <div className="col-md-5 my-auto text-center text-white text-md-left">
                        <h1 className="learnworlds-subheading-large">Explore your creativity with thousands of courses.</h1>
                        <br />
                        <p>Develop critical tech skills. Build tech teams. And convert your goals into gains.</p>
                    </div>
                </div>
                <br /><br />
                <div className="row justify-content-center" id="div2">
                    <div className="col-md-3 text-center border mx-2 p-3" style={{ backgroundColor: 'whitesmoke' }}>
                        <i className="fa fa-check fa-3x text-danger"></i>
                        <h5 className="font-weight-bold">Community discussion</h5>
                        <p>
                            You can communicate with writers and other subscribers under each course to ask questions and engage in helpful discussion.
                        </p>
                    </div>
                    <div className="col-md-3 text-center border mx-2 p-3" style={{ backgroundColor: 'whitesmoke' }}>
                        <i className="fa fa-check fa-3x text-danger"></i>
                        <h5 className="font-weight-bold">Certificates included</h5>
                        <p>
                            You will receive a course completion certificate after finishing the course.
                        </p>
                    </div>
                    <div className="col-md-3 text-center border mx-2 p-3" style={{ backgroundColor: 'whitesmoke' }}>
                        <i className="fa fa-check fa-3x text-danger"></i>
                        <h5 className="font-weight-bold">Related Resources</h5>
                        <a href='https://www.designgurus.io' target='_blank'><img src={designgurus} style={{ 'width': '40%' }} /></a>
                        <br /><br />
                        <a href='https://www.permit.io' target='_blank'><img src={permitio} style={{ 'width': '40%' }} /></a>
                    </div>
                </div>
                <br />
                <div className="row justify-content-center">
                    <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>Our Products</h1>
                </div>
                <br />
            </div>
            <div className="container">
                <div className='row px-2 px-md-0 justify-content-around'>
                    <div className='col-md-5 pt-4 pb-3 mb-md-0 mb-3 px-4 border' style={{ backgroundColor: '', boxShadow: '0 0 11px rgba(33,33,33,.2)', borderRadius: '10px' }}>
                        <div className='row'>
                            <div className='col-12'>
                                <LazyLoadImage effect="blur" className='w-100' src={courses} style={{ borderRadius: '10px' }} />
                            </div>
                            <div className='col-12 mt-3'>
                                <h1 className='font-weight-bold'><i class="fa fa-code bg-danger text-white rounded-circle p-1" aria-hidden="true"></i> Courses</h1>
                            </div>
                            <div className='col-12 mt-3'>
                                <ul style={{ listStyleType: 'none' }} id="products" className='pl-0'>
                                    <li><NavLink to={`/designgurus/`}>DesignGurus Courses</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                    <li><NavLink to={`/educative/`}>Educative Courses</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                    <li><NavLink to={`/pluralsight/`}>PluralSight Courses</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                    <li><NavLink to={`/skillshare/`}>SkillShare Courses</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5 pt-4 pb-3 mb-md-0 mb-3 px-4 border' style={{ backgroundColor: '', boxShadow: '0 0 11px rgba(33,33,33,.2)', borderRadius: '10px' }}>
                        <div className='row'>
                            <div className='col-12'>
                                <LazyLoadImage effect="blur" className='w-100' src={coupons} style={{ borderRadius: '10px' }} />
                            </div>
                            <div className='col-12 mt-3'>
                                <h1 className='font-weight-bold'><i class="fa fa-file-o bg-success text-white rounded-circle p-1" aria-hidden="true"></i> Coupons</h1>
                            </div>
                            <div className='col-12 mt-3'>
                                <ul style={{ listStyleType: 'none' }} id="products" className='pl-0'>
                                    <li><NavLink to={`/coupons/educative`}>Educative.io</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                    <li><NavLink to={`/coupons/nasco`}>Nasco</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                    <li><NavLink to={`/coupons/slumberkins`}>SlumberKins</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                    <li><NavLink to={`/coupons/nestlearning`}>Nest Learning</NavLink><i class="fa fa-chevron-right mt-2" style={{ float: 'right' }} aria-hidden="true"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </div>
            <br />
            <div className='container-fluid'>
                <div className='row justify-content-around' style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                    <div className="col-md-4 my-auto text-white pt-5 pt-md-0">
                        <h1>Hands-on coding environments</h1>
                        <br />
                        <p className='text-justify'>You don’t get better at swimming by watching others. Coding is no different. Practice as you learn with live code environments inside your browser.</p>
                        <div className='row'>
                            <div className='col-6'>
                                <span>Run Live Applications In Your Browser</span>
                            </div>
                            <div className='col-6'>
                                <span>Hands-on Projects: Build Real World Applications</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 py-5">
                        <LazyLoadImage effect="blur" className='w-100' src={second} />
                    </div>
                </div>
            </div>
            <br />
            <div className='container-fluid'>
                <div className='row justify-content-around'>
                    <div className="col-md-4 py-5">
                        <LazyLoadImage effect="blur" className='w-100' src={third} />
                    </div>
                    <div className="col-md-4 my-auto">
                        <h1>Why Coding Courses Hub?</h1>
                        <br />
                        <p className='text-justify'>Whether you're a singular hoping to learn Python to propel your vocation or a venture group hoping to cut process durations, accelerate onboarding, or give your groups the abilities to understand your techniques, we eliminate the difficulties and road obstructions dialing you back. We're propelling the world's tech labor force, and that beginnings with making your work more proficient and powerful — and giving you more to celebrate.</p>
                    </div>
                </div>
            </div>
            <br />
            <div className='container-fluid'>
                <div className='row justify-content-center pb-md-0 pb-5' style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                    <div className="col-md-3 py-5 text-white">
                        <h3>Popular Coding Courses Hub Skills topics to learn now</h3>
                    </div>
                    <div className="col-md-8 my-auto">
                        <div className='row justify-content-center text-center'>
                            <div className='col-md-2 col-4 mb-2 mb-md-0'>
                                <NavLink to={`/python/`}><LazyLoadImage effect="blur" className='w-75' src={python} /></NavLink>
                            </div>
                            <div className='col-md-2 col-4'>
                                <NavLink to={`/csharp/`}><LazyLoadImage effect="blur" className='w-75' src={csharp} /></NavLink>
                            </div>
                            <div className='col-md-2 col-4'>
                                <NavLink to={`/javascript/`}><LazyLoadImage effect="blur" className='w-75' src={javascript} /></NavLink>
                            </div>
                            <div className='col-md-2 col-4'>
                                <NavLink to={`/react/`}><LazyLoadImage effect="blur" className='w-75' src={react} /></NavLink>
                            </div>
                            <div className='col-md-2 col-4'>
                                <NavLink to={`/java/`}><LazyLoadImage effect="blur" className='w-75' src={java} /></NavLink>
                            </div>
                            <div className='col-md-2 col-4'>
                                <NavLink to={`/angular/`}><LazyLoadImage effect="blur" className='w-75' src={angular} /></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='container-fluid'>
                <div className='row justify-content-around'>
                    <div className="col-md-4 my-auto">
                        <h1>Why Students Choose Coding Courses Hub?</h1>
                        <br />
                        <p className='text-justify'>Coding Courses Hub is a comprehensive e-learning platform that provides students with quality content, expert instructors, flexible learning options, hands-on experience, and a supportive community. These factors make it an attractive option for students who want to develop their skills and advance their careers.</p>
                    </div>
                    <div className="col-md-4 py-5">
                        <LazyLoadImage effect="blur" className='w-100' src={fourth} />
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}
